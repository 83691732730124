window.HockeyStackSurveys = (() => {
  'use strict';

  const HockeyStackSurveys = {};
  console.log('Ready for hockey-stick growth (with user research)?');

  // VARIABLES
  let serverURL = 'https://hockeystack.com/surveys';

  const hsScript = document.querySelector('script[src*=hockeystack-surveys]');
  const apiKey = hsScript.getAttribute('apikey') || hsScript.getAttribute('data-apikey');
  const isFingerprint = !!(hsScript.getAttribute('cookieless') || hsScript.getAttribute('data-cookieless') || hsScript.getAttribute('data-cookieless') === '');
  const crossDomain = !!(hsScript.getAttribute('cross-domain') || hsScript.getAttribute('data-cross-domain')) && !isFingerprint;
  HockeyStackSurveys.cookieless = isFingerprint;
  if (hsScript.getAttribute('data-server-url')) serverURL = hsScript.getAttribute('data-server-url') || serverURL;

  let identity;
  if (hsScript.getAttribute('data-identity')) identity = hsScript.getAttribute('data-identity');

  const documentAlias = document;
  const navigatorAlias = navigator;
  const windowAlias = window;

  let customerObject;
  let userObject;
  let originalUUID; // for cookieless, remove the original cookie and store the original uuid for the next req
  let surveys;
  let surveyOptions;
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  const doneSurveys = JSON.parse(localStorage.getItem('HockeyStackSurveys_doneSurveys')) || [];

  const closeSurveys = (JSON.parse(localStorage.getItem('HockeyStackSurveys_closeSurveys')) || []).filter(survey => new Date() - new Date(survey.ld) < 1000 * 60 * 60 * 24);
  localStorage.setItem('HockeyStackSurveys_closeSurveys', JSON.stringify(closeSurveys));

  HockeyStackSurveys.init = function (apiKey) {
    if (isBot() || navigatorAlias.webdriver) return; // if bot, don't run the script

    history.replaceState(null, null, document.URL);

    if (apiKey !== null) HockeyStackSurveys.apiKey = apiKey;

    // check if revisiting user (added extra checks for cookieless)
    if (!(getCookie('hs_uuid') || isFingerprint)) setCookie('hs_uuid', getId(), 365);
    else if (getCookie('hs_uuid') && isFingerprint) {
      originalUUID = getCookie('hs_uuid');
      removeCookie('hs_uuid');
    }

    customerObject = {
      website: window.location.hostname,
      href: window.location.href,
      apiKey: HockeyStackSurveys.apiKey,
      isFingerprint,
      canCauseDuplicationIssue: document.cookie.includes('hs_uuid') && isFingerprint,
      hs_uuid: getCookie('hs_uuid'),
      connectedToHockeyStack: typeof HockeyStack !== 'undefined',
      version: '1.0.0'
    };
    userObject = getUserProperties();

    if (window.location.search === '?test_integration=true') testIntegration();
    else fetchSurveys();
  };

  HockeyStackSurveys.identify = newIdentity => {
    identity = newIdentity;
    userObject.identity = newIdentity;
  };

  // HOCKEYSTACK METHODS
  HockeyStackSurveys.serialize = value => {
    if (typeof value === 'object' || typeof value === 'number') {
      value = JSON.stringify(value);
    }
    return value;
  };

  // OTHER METHODS
  const fetchSurveys = () => {
    if (isBot() || navigatorAlias.webdriver) return; // do not send it at all

    const request = new XMLHttpRequest();

    const requestObj = { customerObject, userObject };

    request.open('POST', serverURL + '/client/list-surveys', true);
    request.setRequestHeader('Content-Type', 'application/json');

    request.onreadystatechange = () => {
      if (request.readyState === 4 && request.status === 200) {
        const result = JSON.parse(request.responseText);
        surveys = result.surveys;
        surveyOptions = result.options;

        if (surveys.length > 0) {
          loadCSS();
          loadSurveys();
        }
      }
    };

    request.send(JSON.stringify(requestObj));
  };

  const loadCSS = () => {
    const head = document.getElementsByTagName('HEAD')[0];
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = 'https://cdn.jsdelivr.net/npm/hockeystack-surveys-tool@latest/hockeystack-surveys.css';
    head.appendChild(link);
  };

  const loadSurveys = () => {
    asyncEach(
      surveys,
      (survey, cursor, callback) => {
        if (doneSurveys.includes(survey._id)) return callback();
        if (closeSurveys.find(s => s.id === survey._id)) return callback();

        survey.answers = [];

        if (survey.launchTime === 'immediate') {
          displaySurvey(survey, callback);
        } else if (survey.launchTime === 'delay') {
          setTimeout(() => displaySurvey(survey, callback), survey.launchDelay * 1000);
          callback();
        } else if (survey.launchTime === 'goal') {
          if (!survey.goal) return callback();

          const compareDefinition = (value, detail, type) => {
            // check if regex is valid
            if (!value || detail.includes('regex')) {
              try {
                const regex = new RegExp(value.match(/\/.*\//)[0].slice(1, -1).replace(/[()]/g, '\\$&'));
              } catch (e) { value = '/^thespanishinquisiton$/'; } // NOBODY expects the spanish inquisition
            }

            if (type.includes('selector')) {
              let actionElementEscaped;
              let regexTag;

              if (value.includes('>')) {
                let f = '';
                const d = '[( )a-z.0-9_-]*';
                value.replace(/\./g, '\\$&').split(' > ').forEach(el => { f += d + el; });
                actionElementEscaped = f.replace(/[()]/g, '\\$&').replace(/:nth-child/g, '[( )a-z.0-9_-]*:nth-of-type') + '($|.[^\\s]*?$)';
              } else {
                actionElementEscaped = value.replace(/[()]/g, '\\$&');
              }

              if (detail.includes('regex')) {
                actionElementEscaped = actionElementEscaped || value.match(/\/.*\//)[0].slice(1, -1).replace(/[()]/g, '\\$&');
                regexTag = value.match(/[a-z]+$/) ? value.match(/[a-z]+$/)[0] : null;
                return new RegExp(actionElementEscaped, (regexTag || 'gi'));
              } else if (detail.includes('includes')) {
                return new RegExp(actionElementEscaped, 'gi');
              } else {
                if (value.includes('>')) return new RegExp(actionElementEscaped, 'gi');
                else return new RegExp(`^${actionElementEscaped}$`, 'gi');
              }
            } else if (type.includes('text')) {
              let actionTextEscaped;
              let regexTag;
              if (detail.includes('regex')) {
                actionTextEscaped = value.match(/\/.*\//)[0].slice(1, -1).replace(/[()]/g, '\\$&');
                regexTag = value.match(/[a-z]+$/) ? value.match(/[a-z]+$/)[0] : null;
                return new RegExp(actionTextEscaped, (regexTag || 'gi'));
              } else if (detail.includes('includes')) {
                return new RegExp(value.replace(/[()+]/g, '\\$&'), 'gi');
              } else if (detail.includes('exact')) {
                return new RegExp(`^${value}$`);
              }
            } else if (type.includes('view')) {
              const url = value;
              const domain = url.split('/')[0] === '' ? '.[a-zA-Z-]+' : url.split('/')[0].replace('www.', '').replace(/\./g, '\\.');
              const urlPath = url.split('/').slice(1).join('/').replace(/\//g, '\\$&').replace('?', '\\$&');

              let actionURLEscaped;
              let regexTag;
              if (detail.includes('regex')) {
                actionURLEscaped = url.match(/\/.*\//)[0].slice(1, -1);
                regexTag = url.match(/[a-z]+$/) ? url.match(/[a-z]+$/)[0] : 'gi';
                return new RegExp(`(?<=/)[a-z-]*${actionURLEscaped}`, regexTag);
              } else if (detail.includes('includes')) {
                return new RegExp(`(www.|)${domain}/.*${urlPath}.*(\\.[a-z]+|)(/|)$`, 'gi');
              } else if (detail.includes('exact')) {
                const domain = url.split('/')[0] === '' ? '[.a-zA-Z-]+' : url.split('/')[0].replace('www.', '').replace(/\./g, '\\.');
                return new RegExp(`${domain}/${urlPath}(\\.[a-z]+|)(/|)(\\?.*|)((#[^/]*|))$`, 'gi');
              }
            }
          };

          let definition = [];
          let continueClickFunctions = true;
          const clickFunctions = [];
          const clickListener = event => {
            const target = event.target;

            clickFunctions.forEach(func => {
              if (func(target)) {
                displaySurvey(survey, callback);
                document.removeEventListener('click', clickListener);
              }
            });
          };

          survey.goal.definition.forEach(def => { definition = definition.concat(def); });
          definition.forEach(def => {
            if (def.actionType === 'click text') clickFunctions.push(target => compareDefinition(def.actionText, def.actionDetail, def.actionType).test(HockeyStack.getClickInfo(target).text) && (def.pageUrl ? compareDefinition(def.pageUrl, def.actionDetail, 'view').test(window.location.href) : true));
            else if (def.actionType === 'click selector') clickFunctions.push(target => compareDefinition(def.actionElement, def.actionDetail, def.actionType).test(HockeyStack.getClickInfo(target).element) && (def.pageUrl ? compareDefinition(def.pageUrl, def.actionDetail, 'view').test(window.location.href) : true));
            else if (def.actionType === 'view') {
              const match = compareDefinition(def.actionURL, def.actionDetail, def.actionType).test(window.location.href);
              if (match) {
                displaySurvey(survey, callback);
                continueClickFunctions = false;
              }
            }
          });

          if (!continueClickFunctions) return callback();
          if (clickFunctions.length) document.addEventListener('click', clickListener);
          callback();
        } else if (survey.launchTime === 'exit') {
          const exitIntentHandler = event => {
            if (!event.toElement && !event.relatedTarget) {
              document.removeEventListener('mouseout', exitIntentHandler);
              setTimeout(() => displaySurvey(survey, callback), 100);
            }
          };
          document.addEventListener('mouseout', exitIntentHandler);
          callback();
        }
      },
      () => {

      }
    );
  };

  const emptyFunction = () => true;

  const displaySurvey = (survey, callback) => {
    window.addEventListener('beforeunload', emptyFunction);

    document.addEventListener('keydown', escListener);
    document.addEventListener('keydown', surveyKeybindings);

    survey.open = true;

    if (customerObject.connectedToHockeyStack) {
      HockeyStack.goal('View Survey', {
        integration: 'HockeyStack Surveys',
        surveyName: survey.name,
        surveyId: survey._id
      });
    }

    // create general structure
    let surveyContainer = document.createElement('div');
    surveyContainer.id = 'hs-surveys__survey-container';
    surveyContainer.dataset.survey = survey._id;
    document.body.appendChild(surveyContainer);

    if (survey.theme && survey.theme.buttonColor) surveyContainer.style.setProperty('--hs-button-color', survey.theme.buttonColor);
    if (survey.theme && survey.theme.buttonTextColor) surveyContainer.style.setProperty('--hs-button-text-color', survey.theme.buttonTextColor);
    if (survey.theme && survey.theme.titleTextColor) surveyContainer.style.setProperty('--hs-title-text-color', survey.theme.titleTextColor);
    if (survey.theme && survey.theme.subtitleTextColor) surveyContainer.style.setProperty('--hs-subtitle-text-color', survey.theme.subtitleTextColor);

    // position survey
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (survey.isCorner) {
      surveyContainer.classList.add('corner');
    } else {
      document.body.classList.add('noscroll');
      document.body.style.top = `-${scrollTop}px`;
      document.addEventListener('click', event => {
        if (event.target.id === 'hs-surveys__survey-container') closeSurvey(event);
      });
    }

    const surveyCard = document.createElement('div');
    surveyCard.classList.add('hs-surveys__survey-card');
    surveyContainer.appendChild(surveyCard);

    const closeButton = document.createElement('a');
    closeButton.classList.add('hs-surveys__survey-close-button');
    closeButton.innerHTML = '×';
    closeButton.onclick = closeSurvey;
    surveyCard.appendChild(closeButton);

    const branding = document.createElement('a');
    branding.id = 'hs-surveys__survey-branding';
    branding.innerHTML = 'Made with HockeyStack';
    branding.href = 'https://www.hockeystack.com/surveys/';
    if (surveyOptions && !surveyOptions.branding) branding.classList.add('hs-surveys__hidden');
    surveyCard.appendChild(branding);
    const brandingValidityCheckHandler = () => {
      if (!isBrandingValid(branding)) {
        surveyContainer.remove();
        surveyContainer = null;
      } else {
        setTimeout(brandingValidityCheckHandler, 1000);
      }
    };
    setTimeout(brandingValidityCheckHandler, 1000);

    asyncEach(
      survey.questions,
      (question, cursor, callback) => {
        displayQuestion(question, cursor, callback, survey, surveyCard, branding);
      },
      () => {
        if (customerObject.connectedToHockeyStack) {
          HockeyStack.goal('Complete Survey', {
            integration: 'HockeyStack Surveys',
            surveyName: survey.name,
            surveyId: survey._id
          });
        }
        sendResponse('beacon', survey, callback);
      }
    );
  };

  const asyncEach = (arr, iteratee, callback) => {
    let cursor = -1;

    const nextIteratee = () => {
      if (cursor === arr.length - 1) return callback();
      iteratee(arr[++cursor], cursor, nextIteratee);
    };

    nextIteratee();
  };

  const displayQuestion = (question, cursor, callback, survey, surveyCard, branding) => {
    const oldQuestion = surveyCard.querySelector('.hs-surveys__survey-question-container');
    if (oldQuestion) oldQuestion.remove();

    const questionContainer = document.createElement('div');
    questionContainer.classList.add('hs-surveys__survey-question-container');
    questionContainer.dataset.type = question.questionType;
    surveyCard.insertBefore(questionContainer, branding);

    const questionText = document.createElement('span');
    questionText.classList.add('hs-surveys__survey-question-text');
    questionText.innerHTML = question.question;
    questionContainer.appendChild(questionText);

    const questionSubtitle = document.createElement('span');
    questionSubtitle.classList.add('hs-surveys__survey-question-subtitle');
    questionSubtitle.innerHTML = question.subtitle || '';
    if (!question.subtitle) questionSubtitle.classList.add('hs-surveys__survey-question-subtitle--empty');
    questionContainer.appendChild(questionSubtitle);

    const nextButton = document.createElement('button'); // append later to maintain order
    nextButton.classList.add('hs-surveys__survey-question-next-button');
    nextButton.innerHTML = (question.buttonText || (cursor === survey.questions.length - 1 ? 'Send' : 'Next')) + ' ↵';
    nextButton.onclick = () => {
      const errorElement = document.querySelector('.hs-surveys__survey-error');
      let isValid = false;
      let answer;

      if (['long-answer', 'short-answer'].includes(question.questionType)) {
        answer = document.querySelector('.hs-surveys__survey-question-textbox').value.trim();
        isValid = answer.length > 0;
      } else if (['nps', 'single-select', 'multiple-select'].includes(question.questionType)) {
        answer = [...document.querySelectorAll('.hs-surveys__survey-question-choice')].filter(choice => choice.checked).map(choice => choice.value);
        isValid = answer.length > 0;
        if (['nps', 'single-select'].includes(question.questionType) && isValid) answer = answer[0];
      } else if (question.questionType === 'announcement') {
        isValid = true;
      }

      if (!isValid) {
        errorElement.style.display = 'block';
        errorElement.innerHTML = 'Please fill in the field';
        return false;
      }

      errorElement.style.display = 'none';
      errorElement.innerHTML = '';

      const answerAddedIndex = survey.answers.findIndex(answer => answer.question === question._id);
      const currentAnswer = {
        question: question._id,
        ...(question.questionType !== 'announcement' && { [Array.isArray(answer) ? 'answerChoices' : question.questionType === 'nps' ? 'answerNumber' : 'answerText']: answer })
      };

      if (answerAddedIndex === -1) survey.answers.push(currentAnswer);
      else survey.answers[answerAddedIndex] = currentAnswer;

      if (customerObject.connectedToHockeyStack) {
        HockeyStack.goal('Answer Survey Question', {
          integration: 'HockeyStack Surveys',
          surveyName: survey.name,
          surveyId: survey._id,
          question: question.question,
          questionType: question.questionType,
          questionId: question._id,
          ...(question.questionType !== 'announcement' && { answer: Array.isArray(answer) ? answer.join(',') : answer })
        });
      }

      callback();
    };

    if (['long-answer', 'short-answer'].includes(question.questionType)) {
      const questionTextbox = document.createElement(question.questionType === 'short-answer' ? 'input' : 'textarea');
      questionTextbox.classList.add('hs-surveys__survey-question-textbox');
      questionTextbox.rows = 5;
      questionContainer.appendChild(questionTextbox);
      questionTextbox.focus();
      questionTextbox.select();
    } else if (['multiple-select', 'single-select', 'nps'].includes(question.questionType)) {
      const choicesContainer = document.createElement('div');
      choicesContainer.classList.add('hs-surveys__survey-question-choices', `hs-surveys__${question.questionType}-choices`);
      questionContainer.appendChild(choicesContainer);

      if (question.questionType === 'nps') question.choices = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      question.choices.forEach((choice, index) => {
        const choiceElementLabel = document.createElement('label');
        choiceElementLabel.classList.add('hs-surveys__survey-question-choice-label', `hs-surveys__${question.questionType}-choice-label`);
        choicesContainer.appendChild(choiceElementLabel);

        const choiceElement = document.createElement('input');
        choiceElement.classList.add('hs-surveys__survey-question-choice', `hs-surveys__${question.questionType}-choice`);
        choiceElement.type = question.questionType === 'multiple-select' ? 'checkbox' : 'radio';
        choiceElement.name = question._id;
        choiceElement.value = choice;
        choiceElementLabel.appendChild(choiceElement);

        const choiceMark = document.createElement('span');
        choiceMark.classList.add('hs-surveys__survey-question-choicemark', `hs-surveys__${question.questionType}-choicemark`);
        if (question.questionType !== 'nps') {
          const choiceMarkLabel = document.createElement('span');
          choiceMarkLabel.classList.add('hs-surveys__survey-question-choicemarklabel', `hs-surveys__${question.questionType}-choicemarklabel`);
          choiceMarkLabel.innerText = alphabet[index];
          choiceMark.appendChild(choiceMarkLabel);
        }
        choiceElementLabel.appendChild(choiceMark);

        choiceElementLabel.innerHTML += choice;
      });

      document.querySelectorAll('.hs-surveys__survey-question-choice-label .hs-surveys__survey-question-choice').forEach(choiceElement => {
        choiceElement.onchange = () => {
          if (choiceElement.checked) {
            if (['single-select', 'nps'].includes(question.questionType)) document.querySelectorAll('.hs-surveys__survey-question-choice-label.hs-surveys__checked').forEach(label => label.classList.remove('hs-surveys__checked'));
            choiceElement.parentElement.classList.add('hs-surveys__checked');
          } else choiceElement.parentElement.classList.remove('hs-surveys__checked');
        };
      });
    }

    const errorElement = document.createElement('span');
    errorElement.classList.add('hs-surveys__survey-error');
    questionContainer.appendChild(errorElement);

    questionContainer.appendChild(nextButton);
  };

  let lastKeyDown = '';
  let lastKeyDownTimestamp = new Date();
  const surveyKeybindings = event => {
    const questionContainer = document.querySelector('.hs-surveys__survey-question-container');
    if (!questionContainer) return document.removeEventListener('keydown', surveyKeybindings);
    let key = event.key.toUpperCase();
    const questionType = document.querySelector('.hs-surveys__survey-question-container').dataset.type;

    if (questionType === 'nps' && '1234567890'.indexOf(key) + 1) {
      if (key === '0' && lastKeyDown === '1' && (new Date() - lastKeyDownTimestamp) < 2000) key = '10';
      else if (key === '0') return;
      document.querySelector(`.hs-surveys__survey-question-choice-label:nth-of-type(${key}) .hs-surveys__survey-question-choice`).click();
    } else if (['multiple-select', 'single-select'].includes(questionType) && alphabet.indexOf(key) + 1 && alphabet.indexOf(key) < document.querySelectorAll('.hs-surveys__survey-question-choice-label').length) {
      document.querySelector(`.hs-surveys__survey-question-choice-label:nth-of-type(${alphabet.indexOf(key) + 1}) .hs-surveys__survey-question-choice`).click();
    } else if (key === 'ENTER') document.querySelector('.hs-surveys__survey-question-next-button').click();

    lastKeyDown = key;
    lastKeyDownTimestamp = new Date();
  };

  const escListener = event => {
    if (event.key === 'Escape') closeSurvey(event);
  };

  const closeSurvey = event => {
    const openSurveys = [...document.querySelectorAll('#hs-surveys__survey-container')];
    const removedSurvey = openSurveys[openSurveys.length - 1];
    surveys.find(survey => survey._id === removedSurvey.dataset.survey).open = false;
    removedSurvey.remove();

    closeSurveys.push({ id: removedSurvey.dataset.survey, ld: new Date().valueOf() });
    localStorage.setItem('HockeyStackSurveys_closeSurveys', JSON.stringify(closeSurveys));

    if (!surveys.find(survey => survey.open)) {
      document.body.classList.remove('noscroll');
      window.scrollTo(0, parseInt(document.body.style.top.substr(1, document.body.style.top.indexOf('px'))));
      window.removeEventListener('beforeunload', emptyFunction);
    }
    document.removeEventListener('keydown', escListener);
  };

  // prevents branding from being removed
  const isBrandingValid = branding => !!(branding || branding.offsetWidth || branding.offsetHeight || branding.getClientRects().length);

  const sendResponse = (type, survey, callback) => {
    if (isBot() || navigatorAlias.webdriver) return; // do not send it at all

    const requestObj = { customerObject, userObject, currentSurvey: survey._id, currentAnswers: survey.answers };

    if (type === 'beacon') sendReqViaBeacon(requestObj);
    else sendReqViaXHR(requestObj);

    doneSurveys.push(survey._id);
    localStorage.setItem('HockeyStackSurveys_doneSurveys', JSON.stringify(doneSurveys));
    closeSurvey();
    if (survey.launchTime === 'immediate') callback();
  };

  const supportsSendBeacon = () =>
    typeof navigatorAlias === 'object' &&
    typeof navigatorAlias.sendBeacon === 'function' &&
    typeof Blob === 'function';

  const sendReqViaBeacon = requestObj => {
    const isSupported = supportsSendBeacon();

    if (!isSupported) return false;

    let success = false;

    try {
      success = navigatorAlias.sendBeacon(serverURL + '/client/respond', JSON.stringify(requestObj));
    } catch (e) {
      return false;
    }
    return success;
  };

  const sendReqViaXHR = requestObj => {
    const request = new XMLHttpRequest();
    request.open('POST', serverURL + '/client/respond', true);
    request.setRequestHeader('Content-Type', 'text/plain; charset=UTF-8');
    request.send(JSON.stringify(requestObj));

    request.onreadystatechange = () => true;
  };

  const testIntegration = () => {
    if (isBot() || navigatorAlias.webdriver) return; // do not send it at all

    const request = new XMLHttpRequest();

    const requestObj = { customerObject, userObject };

    request.open('POST', serverURL + '/client/test-integration', true);
    request.setRequestHeader('Content-Type', 'application/json');

    request.onreadystatechange = () => {
      if (request.readyState == 4) {
        if (request.status == 200) {
          window.close();
        } else {
          alert('An error occured');
        }
      }
    };

    request.send(JSON.stringify(requestObj));
  };

  const getId = () => getCookie('hs_uuid') || generateUUID();

  const generateUUID = () => {
    let d = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  };

  const setCookie = (cookieKey, value, days) => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    const cookieValue = value + ((days == null) ? '' : '; expires=' + date.toUTCString()) + '; path=/;SameSite=Lax' + (crossDomain ? `;domain=${window.location.hostname}` : '');
    documentAlias.cookie = cookieKey + '=' + cookieValue;
  };

  const getCookie = cookieKey => {
    let i;
    let k;
    let v;
    const cookieArray = documentAlias.cookie.split(';');

    for (i = 0; i < cookieArray.length; i++) {
      k = cookieArray[i].substr(0, cookieArray[i].indexOf('='));
      v = cookieArray[i].substr(cookieArray[i].indexOf('=') + 1);
      k = k.replace(/^\s+|\s+$/g, '');

      if (k === cookieKey) return v;
    }
    return null;
  };

  const removeCookie = () => {
    document.cookie = `hs_uuid=; expires=${new Date('2020-01-01')}`;
    if (document.cookie.includes('hs_uuid')) document.cookie = 'hs_uuid=;';
  };

  const isBot = () => {
    const userAgent = navigatorAlias.userAgent.toLowerCase();
    return (windowAlias.location.search.includes('disable_abtestingai') ||
  /(preview|funnelytics|crawl|hexometer|curl|lynx|ptst|nuhk|googlebot|googlesecurityscanner|gtmetrix|slurp|ask jeeves\/teoma|ia_archiver|google web preview|mediapartners-google|baiduspider|ezooms|yahooseeker|altavista|mercator|scooter|infoseek|ultraseek|lycos|wget|yandex|yadirectfetcher|magpie-crawler|nutch crawler|cms crawler|domnutch|netseer|digincore|fr-crawler|wesee|aliasio|bingpreview|headlesschrome|facebookexternalhit|facebookplatform|facebookexternalua|bot|crawler|sp(i|y)der|search|worm|fetch|nutch)/i.test(userAgent));
  };

  const getUserProperties = () => ({
    language: navigatorAlias.language,
    platform: navigatorAlias.platform,
    uuid: (isFingerprint ? originalUUID : getId()),
    ...(isFingerprint) && {
      screen: {
        availHeight: windowAlias.screen.availHeight,
        height: windowAlias.screen.height,
        width: windowAlias.screen.width,
        depth: windowAlias.screen.colorDepth + windowAlias.screen.pixelDepth
      },
      mimeTypes: getMimeTypes(),
      plugins: getPlugins(),
      storageEnabled: (!!localStorage).toString() + !!indexedDB + navigatorAlias.cookieEnabled,
      otherInfo: navigatorAlias.hardwareConcurrency + navigatorAlias.javaEnabled()
    },
    identity
  });

  const getMimeTypes = () => {
    let s = '';
    for (const m of navigatorAlias.mimeTypes) { s += m.type; };
    return s;
  };

  const getPlugins = () => {
    let s = '';
    for (const p of navigator.plugins) { s += p.filename; }
    return s;
  };

  if (apiKey) window.addEventListener('load', () => HockeyStackSurveys.init(apiKey));

  return HockeyStackSurveys;
})();
